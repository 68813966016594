#userForm {
    width: 100%;
}

.userForm-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 10px 0 0 0;
}

.userForm-label {
    width: 100%;
    font-size: 0.9rem;
}

.userForm-input {
    width: 50vw;
    margin: 0 10px 0 20px;
}

.validity-check {
    min-width: 30px;
    height: 30px;
}

.input {
    margin: 5px;
    width: 20px;
    height: 20px;
    font-size: small;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: var(--main-color);
    border-radius: 100%;
}

.pending {
    background-color: var(--line-color);
}

.valid {
    background-color: var(--high-color);
}

.invalid {
    background-color: var(--warn-color);
}

#userForm input, #userForm label {
    width: 75%;
    height: 30px;
    font-family: var(--main-type);
}

.switch-userForm {
    margin: 20px 0;
    color: var(--fade-color);
}

#about-me {
    width: 75%;
    height: 120px;
    font-family: var(--main-type);
}

.requirements {
    width: 100%;
    height: 20px;
    margin: 5px 30px 0 0;
    line-height: 10px;
    padding: 0 0 5px 0;
}

.userFormButton {
    margin: 20px 0 80px 0;
}

@media screen and (max-width: 326px){
    .userForm-input {
        margin: 0;
    }
}

@media screen and (min-width: 800px) {

    #userForm {
        text-align: right;
    }

    .userForm-row {
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    .userForm-label {
        min-width: 20vw;
        line-height: 35px;
    }

    .userForm-input {
        min-width: 280px;
    }

    #userForm input, #userForm label {
        width: 100%;
    }

    .requirements {
        width: 360px;
    }

}