nav {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin: 0 0.5rem 1rem 0;
    padding: 1rem;
    font-family: var(--title-type);
    color: var(--high-color);
    line-height: 3vh;
}

.logo {
    text-align: center;
}

.logoImg {
    width: 1.6rem;
}

html.light .logoImg {
    filter: saturate(0%) brightness(0%);
}

nav > div {
    display: flex;
    flex-direction: row;
}

.navItem {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.navIcon {
    position: relative;
    width: 12vw;
    text-align: center;
}

.navText {
    display: none;
    text-align: right;
}

.navOne {
    font-size: 1.2rem;
}

.navTwo {
    font-size: 0.8rem;
}

nav h5 {
    margin: 0 12px;
    text-align: right;
    font-family: "Sansita Swashed", cursive;
}

.notificationDot {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    width: 5px;
    height: 5px;
    margin: auto;
    background: #7a7aff;
    border-radius: 100%;
}

.hasNewNotifications {
    transform-origin: top;
    animation: bellRinger 20s ease-in-out infinite;
}

.navLogin {
    position: static;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 3vh auto;
    padding: 0;
}

.navLogin > div {
    align-items: center;
}

.navLogin .navItem {
    display: none;
}

@media screen and (min-width: 800px) {
    nav {
        display: block;
        position: fixed;
        top: 3vh;
        right: 80vw;
        margin: auto;
        line-height: 3rem;
        border-radius: 1rem;
    }
    html.light nav {
        background: var(--bars-color);
    }
    html.light .navLogin {
        background: none;
    }
    .logoImg {
        width: 50px;
    }
    nav > div {
        flex-direction: column;
        align-items: flex-end;
    }
    .navIcon {
        width: 50px;
        text-align: center;
    }
    .notificationDot {
        top: 1rem;
        left: 2.5rem;
    }
}

@media screen and (min-width: 1200px) {
    nav {
        right: 73vw;
        padding-left: 2rem;
    }
    .navText {
        display: block;
    }
}

@keyframes bellRinger {
    0% {transform: rotate(0deg)}
    1% {transform: rotate(10deg)}
    2% {transform: rotate(-10deg)}
    3% {transform: rotate(0deg)}
}