.notification {
    position: relative;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 5px 10px;
    background: var(--bars-color);
    border-radius: 5px;
}

.unread {
    border: 1px solid var(--high-color);
}

html.light .unread {
    background: var(--fade-color);
}

html.light .notification {
    border: var(--main-border);
}

.notification span {
    margin: auto 15px auto auto;
}

.notification p {
    padding: 10px;
    border-radius: 5px;
}

html.light .notification p {
    background: #e2f2ff;
    border: var(--main-border);
    box-shadow: 0 0 3px -3px #000;
}

@media screen and (min-width: 800px) {

    .notification-date {
        display: inline;
    }

}