#create {
    margin: auto auto 2vh auto;
    width: 100%;
    padding-bottom: 3vh;
    text-align: center;
    border-bottom: 1px solid  var(--line-color);
}

#create textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 8px;
    font-family: var(--main-type);
    border: 1px solid var(--high-color);
    border-radius: 5px;
    box-shadow: 0 2px 5px 1px var(--shadow-color);
}
