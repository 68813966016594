@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&family=Slabo+13px&display=swap');

html {
  --main-color: #202020;
  --bars-color: #282828;
  --oppo-color: #ffffff;
  --high-color: #3CFFA1;
  --warn-color: #D87C27;
  --fade-color: #a5a5a5;
  --line-color: #535353;
  --shadow-color: #111111;
  --main-type: "Slabo 13px", serif;
  --title-type: "Sansita Swashed", cursive;
  margin: auto;
  font-family: var(--main-type);
  font-size: 18px;
  color: var(--oppo-color);
  transition: all 1s ease;
}

html.light {
  --main-color: #ffffff;
  --bars-color: rgba(219, 237, 255, 0.5);
  --oppo-color: #1a1a1a;
  --high-color: #000000;
  --follow-color: #6082ff;
  --unfollow-color: #ff6e00;
  --warn-color: #D87C27;
  --fade-color: #526571;
  --line-color: #a4b8cd;
  --shadow-color: #6f90b6;
  --main-border: 1px solid var(--line-color);
}

html.light a {
  font-weight: bold;
}

body {
  margin: 0;
  background: var(--main-color);
}

a {
  color: var(--high-color);
  text-decoration: none;
}

h1, h1, h3, h4, h5, h6 {
  margin: 1vh auto 3vh auto;
  /*font-family: var(--title-type);*/
  text-align: center;
}

p {
  font-size: medium;
}

main {
  width: 90vw;
  margin: 20px auto auto auto;
}

.primary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small {
  font-size: small;
}

.x-small {
  font-size: x-small;
  line-height: 5px;
}

.fade-text {
  color: var(--fade-color);
}

.warn-text {
  color: var(--warn-color);
}

.center {
  text-align: center;
}

.check-length {
  width: 100%;
  text-align: right;
}

@media screen and (min-width: 800px) {
  main {
    width: 50vw;
  }
}

@media screen and (min-width: 1200px) {
  main {
    width: 40vw;
  }
}