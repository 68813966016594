button {
    outline: none;
    cursor: pointer;
    font-family: var(--main-type);
    font-weight: bold;
    color: var(--main-color);
    background: var(--high-color);
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 10px 1px var(--shadow-color);
}

html.light button {
    /*color: var(--line-color);*/
    background: var(--follow-color);
}

.generic {
    margin: 10px 0;
    width: 80px;
    height: 28px;
}

.follow {
    width: 20px;
    height: 20px;
    margin: 20px 5px;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: large;
    border-radius: 5px;
}

.unfollow {
    background: var(--warn-color);
}

html.light .unfollow {
    background: var(--unfollow-color);
}

.unfollow-rotate {
    transform: rotate(45deg);
}

button:disabled, html.light button:disabled {
    background: var(--fade-color);
}

.exit, html.light .exit {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    font-size: large;
    line-height: 10px;
    background: var(--warn-color);
    transform: rotate(45deg);
}

.lesson button, html.light .lesson button {
    background: transparent;
    box-shadow: none;
}

.action-bar button, html.light .action-bar button {
    color: var(--high-color);
}
