.sidebarRight {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 2vh;
    right: 20vw;
    width: 90vw;
    height: calc(100vh - 120px);
    max-width: 20vw;
}

.mainSearch {
    line-height: 27px;
}

.mainSearch input {
    float: right;
    width: calc(100% - 2rem - 4px);
    height: 25px;
    margin: 0 5px 3vh auto;
    padding: 3px;
    font-family: var(--main-type);
    color: var(--oppo-color);
    background: var(--line-color);
    border: 0;
}

html.light .mainSearch input {
    background: var(--bars-color);
    border: var(--main-border);
}

.userList {
    margin: 0 0 6vh 0;
    box-sizing: border-box;
}

.userList h5 {
    margin: auto auto 10px auto;
}

.userListItem {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 0 10px;
}

html.light .userListItem {
    background: var(--bars-color);
    border: var(--main-border);
    border-radius: 5px;
}

.userListName a {
    line-height: 20px;
}

.andMore {
    text-align: center;
}

@media screen and (min-width: 1000px) {
    .sidebarRight {
        display: flex;
        width: 16vw;
        right: 2vw;
    }
}

@media screen and (min-width: 1200px) {
    .sidebarRight {
        width: 14vw;
        right: 12vw;
    }
}
