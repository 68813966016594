#my-lessons {
    margin: auto;
    text-align: left;
}

.profileHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 3vh 0;
    padding: 0 0 3vh 0;
    border-bottom: 1px solid var(--line-color);
}

.profileDetails {
    display: flex;
    justify-content: space-between;
    margin: 0 0 1vh 0;
}

.profileDetails h3 {
    margin: 0;
    text-align: left;
}

.profileBio {
    padding: 10px;
    font-size: small;
    color: var(--fade-color);
    background: var(--bars-color);
    border-radius: 5px;
}

html.light .profileBio {
}

.profileTabs {
    margin: 2vh auto;
    padding: 1vh;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 5px;
}

html.light .profileTabs {
    background: var(--bars-color);
    border: var(--main-border);
    border-radius: 5px;
}

.profileTabs span {
    margin: 0 10px;
    color: var(--line-color);
}

.currentTab {
    color: var(--fade-color);
}

.userNotFound {
    margin: 100px auto;
    text-align: center;
}
