@import url(https://fonts.googleapis.com/css2?family=Sansita+Swashed&family=Slabo+13px&display=swap);
html {
  --main-color: #202020;
  --bars-color: #282828;
  --oppo-color: #ffffff;
  --high-color: #3CFFA1;
  --warn-color: #D87C27;
  --fade-color: #a5a5a5;
  --line-color: #535353;
  --shadow-color: #111111;
  --main-type: "Slabo 13px", serif;
  --title-type: "Sansita Swashed", cursive;
  margin: auto;
  font-family: "Slabo 13px", serif;
  font-family: var(--main-type);
  font-size: 18px;
  color: #ffffff;
  color: var(--oppo-color);
  transition: all 1s ease;
}

html.light {
  --main-color: #ffffff;
  --bars-color: rgba(219, 237, 255, 0.5);
  --oppo-color: #1a1a1a;
  --high-color: #000000;
  --follow-color: #6082ff;
  --unfollow-color: #ff6e00;
  --warn-color: #D87C27;
  --fade-color: #526571;
  --line-color: #a4b8cd;
  --shadow-color: #6f90b6;
  --main-border: 1px solid var(--line-color);
}

html.light a {
  font-weight: bold;
}

body {
  margin: 0;
  background: #202020;
  background: var(--main-color);
}

a {
  color: #3CFFA1;
  color: var(--high-color);
  text-decoration: none;
}

h1, h1, h3, h4, h5, h6 {
  margin: 1vh auto 3vh auto;
  /*font-family: var(--title-type);*/
  text-align: center;
}

p {
  font-size: medium;
}

main {
  width: 90vw;
  margin: 20px auto auto auto;
}

.primary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small {
  font-size: small;
}

.x-small {
  font-size: x-small;
  line-height: 5px;
}

.fade-text {
  color: #a5a5a5;
  color: var(--fade-color);
}

.warn-text {
  color: #D87C27;
  color: var(--warn-color);
}

.center {
  text-align: center;
}

.check-length {
  width: 100%;
  text-align: right;
}

@media screen and (min-width: 800px) {
  main {
    width: 50vw;
  }
}

@media screen and (min-width: 1200px) {
  main {
    width: 40vw;
  }
}
#lightSwitch {
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

#lightSwitch input {
    display: none;
}

#lightSwitch input + label {
    display: block;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

#lightSwitch input + label span {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #ffc321;
    transition: all 1.5s ease;
}

#lightSwitch input + label span:active {
    transform: rotateY(90deg) scale(0.8);
}

#lightSwitch input:checked + label span {
    background: #333;
    box-shadow: inset 3px 2px 0 #f6c458;
    transform: rotate(180deg);
}

#lightSwitch input:checked + label span:active {
    transform: rotate(359deg) scale(0.8);
}

@media screen and (min-width: 800px) {
    #lightSwitch {
        width: 50px;
        margin: 1.5vh 0;
    }
}
nav {
    z-index: 10;
    display: flex;
    justify-content: space-between;
    margin: 0 0.5rem 1rem 0;
    padding: 1rem;
    font-family: var(--title-type);
    color: var(--high-color);
    line-height: 3vh;
}

.logo {
    text-align: center;
}

.logoImg {
    width: 1.6rem;
}

html.light .logoImg {
    -webkit-filter: saturate(0%) brightness(0%);
            filter: saturate(0%) brightness(0%);
}

nav > div {
    display: flex;
    flex-direction: row;
}

.navItem {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.navIcon {
    position: relative;
    width: 12vw;
    text-align: center;
}

.navText {
    display: none;
    text-align: right;
}

.navOne {
    font-size: 1.2rem;
}

.navTwo {
    font-size: 0.8rem;
}

nav h5 {
    margin: 0 12px;
    text-align: right;
    font-family: "Sansita Swashed", cursive;
}

.notificationDot {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    width: 5px;
    height: 5px;
    margin: auto;
    background: #7a7aff;
    border-radius: 100%;
}

.hasNewNotifications {
    transform-origin: top;
    -webkit-animation: bellRinger 20s ease-in-out infinite;
            animation: bellRinger 20s ease-in-out infinite;
}

.navLogin {
    position: static;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 3vh auto;
    padding: 0;
}

.navLogin > div {
    align-items: center;
}

.navLogin .navItem {
    display: none;
}

@media screen and (min-width: 800px) {
    nav {
        display: block;
        position: fixed;
        top: 3vh;
        right: 80vw;
        margin: auto;
        line-height: 3rem;
        border-radius: 1rem;
    }
    html.light nav {
        background: var(--bars-color);
    }
    html.light .navLogin {
        background: none;
    }
    .logoImg {
        width: 50px;
    }
    nav > div {
        flex-direction: column;
        align-items: flex-end;
    }
    .navIcon {
        width: 50px;
        text-align: center;
    }
    .notificationDot {
        top: 1rem;
        left: 2.5rem;
    }
}

@media screen and (min-width: 1200px) {
    nav {
        right: 73vw;
        padding-left: 2rem;
    }
    .navText {
        display: block;
    }
}

@-webkit-keyframes bellRinger {
    0% {transform: rotate(0deg)}
    1% {transform: rotate(10deg)}
    2% {transform: rotate(-10deg)}
    3% {transform: rotate(0deg)}
}

@keyframes bellRinger {
    0% {transform: rotate(0deg)}
    1% {transform: rotate(10deg)}
    2% {transform: rotate(-10deg)}
    3% {transform: rotate(0deg)}
}
button {
    outline: none;
    cursor: pointer;
    font-family: var(--main-type);
    font-weight: bold;
    color: var(--main-color);
    background: var(--high-color);
    border: none;
    border-radius: 10px;
    box-shadow: 0 3px 10px 1px var(--shadow-color);
}

html.light button {
    /*color: var(--line-color);*/
    background: var(--follow-color);
}

.generic {
    margin: 10px 0;
    width: 80px;
    height: 28px;
}

.follow {
    width: 20px;
    height: 20px;
    margin: 20px 5px;
    padding: 0;
    font-family: Arial, sans-serif;
    font-size: large;
    border-radius: 5px;
}

.unfollow {
    background: var(--warn-color);
}

html.light .unfollow {
    background: var(--unfollow-color);
}

.unfollow-rotate {
    transform: rotate(45deg);
}

button:disabled, html.light button:disabled {
    background: var(--fade-color);
}

.exit, html.light .exit {
    width: 20px;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    font-size: large;
    line-height: 10px;
    background: var(--warn-color);
    transform: rotate(45deg);
}

.lesson button, html.light .lesson button {
    background: transparent;
    box-shadow: none;
}

.action-bar button, html.light .action-bar button {
    color: var(--high-color);
}

#create {
    margin: auto auto 2vh auto;
    width: 100%;
    padding-bottom: 3vh;
    text-align: center;
    border-bottom: 1px solid  var(--line-color);
}

#create textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 8px;
    font-family: var(--main-type);
    border: 1px solid var(--high-color);
    border-radius: 5px;
    box-shadow: 0 2px 5px 1px var(--shadow-color);
}

.lesson {
    position: relative;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 5px 10px;
    background: var(--bars-color);
    border-radius: 5px;
}

html.light .lesson {
    border: var(--main-border);
}

.lesson span {
    margin: auto 15px auto auto;
}

.lesson p {
    padding: 10px;
    border-radius: 5px;
}

html.light .lesson p {
    background: #e2f2ff;
    border: var(--main-border);
    box-shadow: 0 0 3px -3px #000;
}

.lesson-date {
    display: block;
    color: var(--line-color);
}

.lesson-options, html.light .lesson-options {
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--line-color);
    transition: 0.5s;
}

.lesson-options:hover, html.light .lesson-options:hover {
    color: var(--fade-color);
}

button.lesson-options-list, html.light button.lesson-options-list {
    z-index: 1000;
    position: relative; /* important! to be able to click on it! */
    top: 20px;
    padding: 5px;
    font-size: x-small;
    color: var(--fade-color);
    background: var(--main-color);
    border: 1px solid var(--fade-color);
    border-radius: 5px;
    transition: 0.5s;
}

button.lesson-options-list:hover, html.light button.lesson-options-list:hover {
    color: #ff0000;
}

.lesson-options svg {
    margin: 0 0 0 10px;
}

.lesson-modal-bg {
    z-index: 50;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.action-bar {
    position: relative;
    margin: auto;
    font-size: small;
    color: var(--high-color);
}

.like-count {
    display: inline-block;
    min-width: 15px;
}

.lesson-share-notify {
    min-width: 200px;
    top: 0;
    font-size: small;
    color: var(--fade-color);
    background: var(--bars-color);
    border-radius: 5px;
    -webkit-animation: fadeShare 5s ease-in-out;
            animation: fadeShare 5s ease-in-out;
}

@media screen and (min-width: 800px) {

    .lesson-date {
        display: inline;
    }

    .lesson-share-modal {
        width: 500px;
        height: 200px;
    }

}

@-webkit-keyframes fadeShare {
    from {opacity: 1}
    to {opacity: 0}
}

@keyframes fadeShare {
    from {opacity: 1}
    to {opacity: 0}
}
.sidebarRight {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 2vh;
    right: 20vw;
    width: 90vw;
    height: calc(100vh - 120px);
    max-width: 20vw;
}

.mainSearch {
    line-height: 27px;
}

.mainSearch input {
    float: right;
    width: calc(100% - 2rem - 4px);
    height: 25px;
    margin: 0 5px 3vh auto;
    padding: 3px;
    font-family: var(--main-type);
    color: var(--oppo-color);
    background: var(--line-color);
    border: 0;
}

html.light .mainSearch input {
    background: var(--bars-color);
    border: var(--main-border);
}

.userList {
    margin: 0 0 6vh 0;
    box-sizing: border-box;
}

.userList h5 {
    margin: auto auto 10px auto;
}

.userListItem {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 0 10px;
}

html.light .userListItem {
    background: var(--bars-color);
    border: var(--main-border);
    border-radius: 5px;
}

.userListName a {
    line-height: 20px;
}

.andMore {
    text-align: center;
}

@media screen and (min-width: 1000px) {
    .sidebarRight {
        display: flex;
        width: 16vw;
        right: 2vw;
    }
}

@media screen and (min-width: 1200px) {
    .sidebarRight {
        width: 14vw;
        right: 12vw;
    }
}

#my-lessons {
    margin: auto;
    text-align: left;
}

.profileHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0 3vh 0;
    padding: 0 0 3vh 0;
    border-bottom: 1px solid var(--line-color);
}

.profileDetails {
    display: flex;
    justify-content: space-between;
    margin: 0 0 1vh 0;
}

.profileDetails h3 {
    margin: 0;
    text-align: left;
}

.profileBio {
    padding: 10px;
    font-size: small;
    color: var(--fade-color);
    background: var(--bars-color);
    border-radius: 5px;
}

html.light .profileBio {
}

.profileTabs {
    margin: 2vh auto;
    padding: 1vh;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 5px;
}

html.light .profileTabs {
    background: var(--bars-color);
    border: var(--main-border);
    border-radius: 5px;
}

.profileTabs span {
    margin: 0 10px;
    color: var(--line-color);
}

.currentTab {
    color: var(--fade-color);
}

.userNotFound {
    margin: 100px auto;
    text-align: center;
}


#logup {
    position: relative;
    width: 400px;
    max-width: 90vw;
    margin: auto;
    text-align: center;
}

#logup img {
    height: 80px;
}

.logup-title {
    margin: 40px 0 30px 0;
}

#logup-form {
    width: 100%;
}

.logup-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 10px 0 0 0;
}

.logup-label {
    width: 100%;
    font-size: 0.9rem;
}

.logup-input {
    width: 50vw;
    margin: 0 10px 0 20px;
}

.validity-check {
    min-width: 30px;
    height: 30px;
}

.input {
    margin: 5px;
    width: 20px;
    height: 20px;
    font-size: small;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: var(--main-color);
    border-radius: 100%;
}

.pending {
    background-color: var(--line-color);
}

.valid {
    background-color: var(--high-color);
}

.invalid {
    background-color: var(--warn-color);
}

#logup-form input, #logup-form label {
    width: 75%;
    height: 30px;
    font-family: var(--main-type);
}

.switch-logup {
    margin: 20px 0;
    color: var(--fade-color);
}

#about-me {
    width: 75%;
    height: 120px;
    font-family: var(--main-type);
}

.requirements {
    width: 100%;
    height: 20px;
    margin: 5px 30px 0 0;
    line-height: 10px;
    padding: 0 0 5px 0;
}

.logupButton {
    margin: 20px 0 80px 0;
}

@media screen and (max-width: 326px){
    .logup-input {
        margin: 0;
    }
}

@media screen and (min-width: 800px) {

    #logup-form {
        text-align: right;
    }

    .logup-row {
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    .logup-label {
        min-width: 20vw;
        line-height: 35px;
    }

    .logup-input {
        min-width: 280px;
    }

    #logup-form input, #logup-form label {
        width: 100%;
    }

    .requirements {
        width: 360px;
    }

}
#userForm {
    width: 100%;
}

.userForm-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 10px 0 0 0;
}

.userForm-label {
    width: 100%;
    font-size: 0.9rem;
}

.userForm-input {
    width: 50vw;
    margin: 0 10px 0 20px;
}

.validity-check {
    min-width: 30px;
    height: 30px;
}

.input {
    margin: 5px;
    width: 20px;
    height: 20px;
    font-size: small;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: var(--main-color);
    border-radius: 100%;
}

.pending {
    background-color: var(--line-color);
}

.valid {
    background-color: var(--high-color);
}

.invalid {
    background-color: var(--warn-color);
}

#userForm input, #userForm label {
    width: 75%;
    height: 30px;
    font-family: var(--main-type);
}

.switch-userForm {
    margin: 20px 0;
    color: var(--fade-color);
}

#about-me {
    width: 75%;
    height: 120px;
    font-family: var(--main-type);
}

.requirements {
    width: 100%;
    height: 20px;
    margin: 5px 30px 0 0;
    line-height: 10px;
    padding: 0 0 5px 0;
}

.userFormButton {
    margin: 20px 0 80px 0;
}

@media screen and (max-width: 326px){
    .userForm-input {
        margin: 0;
    }
}

@media screen and (min-width: 800px) {

    #userForm {
        text-align: right;
    }

    .userForm-row {
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    .userForm-label {
        min-width: 20vw;
        line-height: 35px;
    }

    .userForm-input {
        min-width: 280px;
    }

    #userForm input, #userForm label {
        width: 100%;
    }

    .requirements {
        width: 360px;
    }

}
.notification {
    position: relative;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
    padding: 5px 10px;
    background: var(--bars-color);
    border-radius: 5px;
}

.unread {
    border: 1px solid var(--high-color);
}

html.light .unread {
    background: var(--fade-color);
}

html.light .notification {
    border: var(--main-border);
}

.notification span {
    margin: auto 15px auto auto;
}

.notification p {
    padding: 10px;
    border-radius: 5px;
}

html.light .notification p {
    background: #e2f2ff;
    border: var(--main-border);
    box-shadow: 0 0 3px -3px #000;
}

@media screen and (min-width: 800px) {

    .notification-date {
        display: inline;
    }

}
#editProfile {
    position: relative;
    width: 400px;
    max-width: 90vw;
    margin: auto;
    text-align: center;
}

.editProfile-title {
    margin: 40px 0 30px 0;
}
